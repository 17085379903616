body {
  padding: 0;
  margin: 0;
  font-family: Heebo, Helvetica, Arial, sans-serif;
  overflow-x: hidden;
  background-color: #273983 !important;
  min-height: 100vh;
  position:relative;
}
body::before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 20vw 20vw;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  content: '';
  bottom: 0;
  right: 0;
}
.container-fluid {
  padding: inherit;
  margin: inherit;
}
p.hia_desc{
  max-width: 500px;
  margin: 0 auto 70px;
  position: relative;
}
p.hia_desc::before {
  background-image: url('https://hiasecure.com/wp-content/uploads/2019/10/fleche-1.png');
  content: '';
  position: absolute;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  top: 0px;
  z-index: 99999;
  background-position: center;
  background-size: contain;
  transform: scaleX(-1) rotate(90deg);
  right: -80px;
}
#hia_btn div.hia_demande_demo {
  width: 300px;
  margin: 0 auto;
  height: 50px;
  display: block;
  line-height: 50px;
  position: absolute;
  top: calc(50% + 50px);
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  cursor: pointer;
  color: white;
  border: none;
  font-size: 14px;
  border-radius: 5px;
  background-color: transparent;
  transition: all 1s ease-in-out;
}
#hia_btn div.btn {
  width: 300px;
  margin: 0 auto;
  height: 50px;
  display: block;
  line-height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  cursor: pointer;
  color: #000000;
  border: 1px solid #28558A;
  font-size: 14px;
  border-radius: 5px;
  background-color: #dce6f2;
  transition:1s all ease-in-out;
  -webkit-transition:1s all ease-in-out;
}
#hia_back_btn img{
  object-fit: contain;
  width: 40px;
}
#hia_back_btn {
  position: absolute;
  top: 20px;
  left: 20Px;
  width: 40px;
  height: 40px;
  z-index: 99999;
  display: block;
  cursor:pointer;
}
#hia_logo img {
  max-width: 100%;
  width: 100%;
  margin: 50px auto;
  width: 400px;
}
#hia_btn div.btn:hover {
  color: #28558A;
  background-color: #fff;
}
#hia_btn {
  position: relative;
  text-align: center;  
  width: 100%;  
  height: calc(100vh);
  margin: 0 auto;
  line-height: calc(100vh);
  color: white;
}
button {
  color: #889DB6 !important;
  background-color: #28558A !important;
}
ul {
  list-style-type: square;
}
ul li ul {
  list-style-type: none;
  color: #731e92;
}
ul li ul li::before {
  content: "\2022";
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 30px;
}
ul li ul li ul {
  list-style-type: none;
  font-family: 'Heebo';
  font-weight: 300;
}
ul li ul li ul li::before {
  content: "\25E6";
  display: inline-block;
  font-size: 25px;
  width: 1em;
  margin-left: -1em
}
ul li ul li ul li ul {
  color: #6e6e6e;
}
ul li ul li ul li ul li::before {
  content: "\2022";
  color: #6e6e6e;
  display: inline-block;
  width: 1em;
  font-size: 10px;
  margin-left: -1em
}
ul li ul li ul li ul li ul {
  list-style-type: none;
}
ul li ul li ul li ul li ul li::before {
    content: "\25AA";
    color: #6e6e6e;
    display: inline-block;
    width: 1em;
    font-size: 20px;
    margin-left: -1em
}
h3{
  font-family: "Myriad Pro";
  font-size: 22px;
  font-weight: 400;
  letter-spacing: .95px;
  line-height: 30px;
  font-weight: 700;
  color: #273983;
  text-decoration: none;
  margin-top: 0;
}
.hia_post_content ul li, .hia_post_content ul li a {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 30px;
  font-family: 'Segoe UI Regular';
  font-weight: 400;
  color: #273983;
  text-decoration: none;
  word-break: break-word;
}
.hide {
  display: none;
}
.show .hia_less, .hide .hia_more{
  display:none;
}
.hia_read_more {
  height: 50px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 9999;
  display: block;
  width: 100px;
  font-weight: 700;
  font-family: "Myriad Pro";
  line-height: 50px;
}
.hia_read_more{
  color: #405eb6;
  cursor:pointer;
}
.hia_post_block:hover {
  opacity: 1;
}
.hia_read_more.show {
  background-image: url(https://hiasecure.com/wp-content/uploads/2019/10/fleche.png);
  background-repeat: no-repeat;
  background-position: right;
  width: 100px;
}
.hia_post_block.show {
  max-height: 900px;
  height: 100%;
  opacity: 1;
}
.hia_read_more.show::before{
  content:"voir plus";
  display:block;
}
.hia_read_more.hide::before{
  content:"voir moins";
  display:block;
}
.show .hia_more,.hide .hia_less {
  position: relative;
  cursor:pointer;
  color: #405eb6;
  font-family: "Myriad Pro";  
  font-size: 15px;  
  font-weight: 700;  
  letter-spacing: 0.75px;  
  line-height: 50px; 
}
.hia_post_content ul li{
  list-style-type: disc;
}
.hia_post_block.show .hia_post_content ul li:nth-child(3),
.hia_post_block.show .hia_post_content ul li:nth-child(4),
.hia_post_block.show .hia_post_content ul li:nth-child(5),
.hia_post_block.show .hia_post_content ul li:nth-child(6){
  display:list-item;
}
.hia_post_content ul li:nth-child(3),
.hia_post_content ul li:nth-child(4),
.hia_post_content ul li:nth-child(5),
.hia_post_content ul li:nth-child(6){
  display:none;
}
.hia_post_content ul {
  padding-left: 20px;
}
.hia_posts {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  width: 100%;
}
.jss2.jss55 {
  padding-left: 50px;
  padding-right: 50px;
  overflow-x: hidden;
}
.hia_post_block {
  position:relative;
  box-shadow: 2px 4px 10px rgba(0,0,0,.18);
  background-color: #fff;
  opacity: .8;
  max-height: 300px;
  height:100%;
  padding: 19px 34px 50px;
  margin: 0 0 34px;
  width: 48%;
  max-width: 48%;
  transition: 1s max-height ease-in-out;
}
h2 {
  margin-bottom: 60px;
}
@media (max-width: 1000px) {
  .hia_post_block {
    max-height: 350px;
  }
}
@media (max-width: 850px) {
  .hia_post_block {
    max-height: 370px;
  }

  p.hia_desc::before{
    display:none;
  }
}
@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  body {
      padding-top: 50px;
      color: #505050;
  }
  #menu{
    display: none;
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  #menu_res{
      display: flex;
      display: -webkit-flex;
  }
  
  .jss2.jss55 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .hia_posts {
    justify-content: space-around;
    -webkit-justify-content: space-around;
  }
  .hia_post_block {
    width: 90%;
    max-width: 90%;
    max-height: 390px;
  }
}